<template>
  <div class="content-global content-tables-OC">
    <div class="global-content-table">
      <filter-component-global
        ref="filterGlobal"
        class="global-filters"
        @setDateRange="setDateRange"
        @setStatusOC="setStatusOC"
        @setTypeOperation="setTypeOperation"
        @setSearch="setSearch"
        :labelName="sLabelNameFilter"
        :placeholderName="sPlaceholderName"
      />
      <no-data-text-component-global
        :sTextEmpty="sTextEmpty"
        :iHeight="0"
        v-if="bEmptyTable"
      />
      <v-data-table
        v-else
        v-show="!isMobile"
        mobile-breakpoint="0"
        :headers="aHeaders"
        :disable-sort="true"
        :items="aTable"
        :loading="bLoadingTable"
        loading-text="Cargando datos..."
        :items-per-page="iItemsPerPage"
        :hide-default-footer="true"
        no-data-text="No hay información disponible para mostrar."
        :footer-props="{
          'items-per-page-text': 'Filas por página',
          'items-per-page-all-text': 'Todos',
        }"
        class="global-table"
      >
        <template slot="progress">
          <v-progress-linear
            color="var(--primary-color-loading-table)"
            indeterminate
          ></v-progress-linear>
        </template>
        <template v-slot:[`item.sActorName`]="{ item }">
          <span v-if="item.sActorName !== null">
            {{ item.sActorName }}
          </span>
          <span v-else class="text-data-empty">
            {{ sEmptyData }}
          </span>
        </template>
        <template v-slot:[`item.sDriverLicensePlate`]="{ item }">
          <span v-if="item.sDriverLicensePlate !== null">
            {{ item.sDriverLicensePlate }}
          </span>
          <span v-else class="text-data-empty">
            {{ sEmptyData }}
          </span>
        </template>
        <template v-slot:[`item.dTotalWeight`]="{ item }">
          <span v-if="item.dTotalWeight !== null">
            {{ formatQuantityGlobal(item.dTotalWeight) }} Kg
          </span>
          <span v-else class="text-data-empty">
            {{ sEmptyData }}
          </span>
        </template>
        <template v-slot:[`item.accions`]="{ item }">
          <v-btn @click="detailOC(item)" class="global-btn-table-detail" icon>
            <v-icon> mdi-eye-outline </v-icon>
          </v-btn>
        </template>
        <template v-slot:footer>
          <footer-table-component-global
            :numPages="numPages"
            :totalItems="totalItems"
            @changeTable="getEmitOC"
            :bInitialCurrentPage="bInitialCurrentPage"
          />
        </template>
      </v-data-table>
      <table-mobile-o-p-component-global
        v-show="isMobile"
        @changeTable="getEmitOC"
        @openDetailItem="detailOC"
        :aTable="aTable"
        :headers="aHeaders"
        :numPages="numPages"
        :totalItems="totalItems"
        :bChangeSelectedItem="bChangeSelectedItem"
        :bInitialCurrentPage="bInitialCurrentPage"
      />
    </div>
  </div>
  <!-- <footer-component-global /> -->
</template>

<script>
export default {
  props: {
    aHeaders: Array,
    sApiGetOC: String,
    sTypeItem: Number,
    sTextTitle: String,
    sLabelNameFilter: String,
    sPlaceholderName: String,
    bChangeSelectedItem: Boolean,
    oDateRange: Object,
  },
  data() {
    return {
      heightMax: 0,
      screenHeight: 0,
      screenWidth: 0,
      bLoadingTable: true,
      bEmptyTable: false,
      iCurrentPage: 1,
      bInitialCurrentPage: false,
      iPageNumber: 1,
      iItemsPerPage: 30,
      numPages: 0,
      totalItems: 0,
      totalView: 0,
      oItemDashboard: {},
      aTable: [],
      isMobile: false,
      aDateRange: [],
      sSearch: "",
      sStartDate: "",
      sEndDate: "",
      aStatusOC: [],
      sTypeOperation: "",
      sEmptyData: "Sin información.",
      sTextEmpty: "<p>No existen operaciones registradas para mostrar</p>",
      bInitialFillFilter: true,
    };
  },
  beforeMount() {
    this.setFillFieldFilter();
    this.getOC();
    this.sStartDate = this.oDateRange.tStart;
    this.sEndDate = this.oDateRange.tEnd;
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    setFillFieldFilter() {
      this.iCurrentPage = this.iCurrentPageGlobal;
      this.iItemsPerPage = this.iItemsPerPageGlobal;
    },
    handleResize() {
      this.$nextTick((e) => {
        if (this.$refs.filterGlobal !== undefined) {
          this.heightMax = this.$refs.filterGlobal.clientHeight;
          this.heightMax = this.heightMax + 90; //123 IS NAVBAR
        }
        if (window.innerWidth > 599) {
          this.isMobile = false;
          this.screenHeight = window.innerHeight - this.heightMax;
        } else {
          this.isMobile = true;
          this.screenHeight = window.innerHeight - 395;
        }

        if (window.innerWidth > 960) {
          this.screenWidth = window.innerWidth - 900;
        } else {
          this.screenWidth = window.innerWidth;
        }
      });
    },
    detailOC(item) {
      let sNameRoute = "";
      switch (this.sTypeItem) {
        case 1:
          sNameRoute = "OPGeneralDetail";
          break;
        case 2:
          sNameRoute = "OPPendingDetail";

          break;
        case 3:
          sNameRoute = "OPPayableDetail";
          break;

        default:
          break;
      }
      this.$router
        .push({
          name: sNameRoute,
          params: { idOP: item.sOrderId },
        })
        .catch((e) => {});
    },
    getEmitOC(obj) {
      this.iCurrentPage = obj.iCurrentPage;
      this.$store.commit("setCurrentPageGlobal", this.iCurrentPage);
      this.iItemsPerPage = obj.iItemsPerPage;
      this.$store.commit("setItemsPerPageGlobal", this.iItemsPerPage);
      this.getOC();
    },
    getOC() {
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          const params = this.getParams();
          DB.get(this.sApiGetOC, {
            params: params,
            headers: {
              Authorization: "Bearer " + this.$store.state.sToken,
            },
          })
            .then((response) => {
              this.$emit(
                "setItemDashboard",
                this.fillItemDashboard(response.data.results)
              );

              this.numPages = response.data.numPages;
              this.totalItems = response.data.total;

              this.aTable = response.data.results.aOrderList.map((e) => {
                return {
                  ...e,
                  dAmountFormat: e.oPrices !== undefined? e.oPrices.oMexicanPeso.dTotalAmountFormat + ' / ' + e.oPrices.oUSDollar.dTotalAmountFormat:0  ,
                  sActorName: e.oActor.sName,
                  sStateName: e.oOrderStatus.sName,
                };
              });
              this.bLoadingTable = false;
              this.bEmptyTable = this.aTable.length < 1;

              this.totalView = this.aTable.length;
              this.$store.commit("refresher", false);
            })
            .catch((error) => {
              this.bLoadingTable = false;
              this.Error(error.response.data);
            });
        })
        .catch((err) => {
          this.Error(err);
        });
    },
    getParams() {
      switch (this.$route.name) {
        case "OPGeneral":
          return {
            sSearch: this.sSearch,
            tStart: this.sStartDate,
            tEnd: this.sEndDate,
            aStatuses: this.aStatusOC,
            sType: this.sTypeOperation,
            iPageNumber: this.iCurrentPage,
            iItemsPerPage: this.iItemsPerPage,
          };
        case "OPPending":
          return {
            sSearch: this.sSearch,
            tStart: this.sStartDate,
            tEnd: this.sEndDate,
            aStatuses: this.aStatusOC,
            sType: this.sTypeOperation,
            iPageNumber: this.iCurrentPage,
            iItemsPerPage: this.iItemsPerPage,
          };
        case "OPPayable":
          return {
            sSearch: this.sSearch,
            tStart: this.sStartDate,
            tEnd: this.sEndDate,
            // aStatuses: this.aStatusOC,
            sType: this.sTypeOperation,
            iPageNumber: this.iCurrentPage,
            iItemsPerPage: this.iItemsPerPage,
          };

        default:
          break;
      }
    },
    fillItemDashboard(Obj) {
      switch (this.$route.name) {
        case "OPGeneral":
          return {
            oFinalizedPurchaseorders: Obj.oFinalizedPurchaseorders,
            oFinalizedPublicOrders: Obj.oFinalizedPublicOrders,
            oFinalizedSalesOrders: Obj.oFinalizedSalesOrders,
          };

        case "OPPending":
          return {
            oPendingPurchaseOrders: Obj.oPendingPurchaseOrders,
            oPendingSalesOrders: Obj.oPendingSalesOrders,
          };

        case "OPPayable":
          if (this.sTabPositionOrderPayable === 0) {
            return {
              oPendingPurchaseOrders: Obj.oPendingPurchaseOrders,
              oPendingPublicOrders: Obj.oPendingPublicOrders,
              oPendingSalesOrders: Obj.oPendingSalesOrders,
            };
          } else {
            return {
              oFinalizedPurchaseOrders: Obj.oFinalizedPurchaseOrders,
              oFinalizedPublicOrders: Obj.oFinalizedPublicOrders,
              oFinalizedSalesOrders: Obj.oFinalizedSalesOrders,
            };
          }

          break;

        default:
          break;
      }
    },
    setSearch(sSearch) {
      // if (!this.bInitialFillFilter) {
      this.bInitialCurrentPage = !this.bInitialCurrentPage;
      // }
      this.sSearch = sSearch;
      this.getOC();
    },
    setTypeOperation(sTypeOperation) {
      // if (!this.bInitialFillFilter) {
      this.bInitialCurrentPage = !this.bInitialCurrentPage;
      // }
      this.sTypeOperation = sTypeOperation;
      this.getOC();
    },
    setDateRange(oDateRange) {
      this.sStartDate = oDateRange.sStartDate;
      this.sEndDate = oDateRange.sEndDate;
      this.getOC();
    },
    setStatusOC(aStatusOC) {
      this.aStatusOC = aStatusOC;
      // if (!this.bInitialFillFilter) {
      this.bInitialCurrentPage = !this.bInitialCurrentPage;
      // }
      this.getOC();
    },
  },
  computed: {
    sSearchGlobal() {
      return this.$store.state.sSearchGlobal;
    },
    iCurrentPageGlobal() {
      return this.$store.state.iCurrentPageGlobal;
    },
    iItemsPerPageGlobal() {
      return this.$store.state.iItemsPerPageGlobal;
    },
    refreshTable() {
      return this.$store.state.refresh;
    },
    sTabPositionOrderPayable() {
      return this.$store.state.sTabPositionOrderPayable;
    },
  },
  watch: {
    refreshTable() {
      this.getOC();
    },
    iCurrentPage() {
      this.getOC();
    },
    bChangeSelectedItem() {
      this.getOC();
    },
    oDateRange() {
      // if (!this.bInitialFillFilter) {
      this.bInitialCurrentPage = !this.bInitialCurrentPage;
      // }
      this.sStartDate = this.oDateRange.tStart;
      this.sEndDate = this.oDateRange.tEnd;
      this.getOC();
    },
  },
};
</script>

<style>
.content-OPGlobal {
  /* background-color: darkslategrey; */
  padding: 0px 30px 0px 35px;
}
</style>
